
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "App",
  setup() {
    const showNav = ref(false);

    return {
      showNav
    }
  }
});
