<template>
  <div class="home">
    <section class="hero-banner is-medium">
      <div class="hero-body">
        <p class="title has-text-white">
          Recruiting on Illidan!
        </p>
        <p class="subtitle has-text-light">
          We're looking for raiders with drive and patience to push content.
        </p>
        <router-link class="button is-dark" to="/apply">
            <strong>Apply Now!</strong>
        </router-link>
      </div>
    </section>
    <section class="is-large">
      <div class="columns is-desktop is-multiline">
        <div class="column is-half">
          <section class="section">
            <h1 class="title has-text-white">Who we are...</h1>
            <h2 class="subtitle has-text-light">
              Memento Mori is an Illidan
              <span class="has-text-danger">[H]</span> Heroic Progression Guild
              with aspirations to progress Mythic. Our ultimate goal is to
              create a community of talented players and elevate each other to a
              higher level.
            </h2>
            <figure class="image is-16by9">
              <img src="https://cdn.memento-guild.com/spire.webp" />
            </figure>
          </section>
        </div>
        <div class="column is-half">
          <section class="section">
            <h1 class="title has-text-white">More about recruitment</h1>
            <h2 class="subtitle has-text-light">
              We are looking for all classes/specs of above average or higher
              skill level. A positive outlook and desire to improve are huge
              components to our success. As our roster grows, so will our
              expectations.
            </h2>
            <figure class="image is-16by9">
              <img
                src="https://cdn.memento-guild.com/maldraxxus_recruits.webp"
              />
            </figure>
          </section>
        </div>
        <div class="column is-full">
          <div class="columns is-desktop is-multiline is-centered">
            <div class="column is-half">
              <section class="section">
                <h1 class="title has-text-white">Memento Mori</h1>
                <h2 class="subtitle has-text-light">
                  A Memento Mori is defined as an object serving as a reminder
                  of death's inevitiblity. When we step into the dungeons,
                  arenas, battlegrounds and raids we become a living reminder to
                  our enemies that death comes for us all.
                </h2>
                <div class="columns is-desktop is-centered">
                  <div class="column is-full">
                    <figure class="image is-16by9">
                      <img src="https://cdn.memento-guild.com/ragnaros.webp" />
                    </figure>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
});
</script>
