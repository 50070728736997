<template>
  <nav id="nav" class="navbar is-fixed-top is-light">
    <div class="container">
      <div class="navbar-brand">
        <router-link class="navbar-item" to="/">
          <img
            src="https://cdn.memento-guild.com/mori-skull-web.webp"
            alt="Logo"
          />
          <span class="title is-size-4">&nbsp;Memento Mori</span>
        </router-link>
        <span class="navbar-burger burger" data-target="navbarMenu"  @click="showNav = !showNav" :class="{ 'is-active': showNav }">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>
      <div id="navbarMenu" class="navbar-menu" :class="{ 'is-active': showNav }">
        <div class="navbar-end has-text-weight-bold">
          <router-link class="navbar-item" to="/">
            Home
          </router-link>
          <router-link class="navbar-item" to="/apply">
            Apply!
          </router-link>
          <router-link class="navbar-item" to="/contact">
            Contact Us
          </router-link>
          <router-link class="navbar-item" to="/team">
            Team
          </router-link>
        </div>
      </div>
    </div>
  </nav>
  <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
  <main class="site-content has-background-dark has-text-light">
    <div class="container is-fullhd">
      <router-view />
    </div>
  </main>
  <footer class="footer has-background-black">
    <div class="columns is-mobile">
      <div class="column">
        <p>
          <strong class="has-text-white">Memento Mori</strong>
        </p>
      </div>
      <div class="column">
        <p class="buttons">
          <a class="button is-small is-dark" href="https://discord.gg/fWzrcBxXCB" target="_blank">
            <font-awesome-icon :icon="['fab', 'discord']" />
          </a>
          <a class="button is-small is-dark" href="https://www.facebook.com/Memento-Mori-345797566489276" target="_blank">
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </a>
          <button class="button is-small is-dark">
            <font-awesome-icon :icon="['fab', 'twitch']" />
          </button>
          <a
            class="button is-small is-dark"
            href="https://twitter.com/Memento_Guild"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </a>
          <button class="button is-small is-dark">
            <font-awesome-icon :icon="['fab', 'youtube']" />
          </button>
          <router-link class="button is-small is-dark" to="/applications">
            A
          </router-link>
        </p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "App",
  setup() {
    const showNav = ref(false);

    return {
      showNav
    }
  }
});
</script>

<style lang="scss">
body {
  padding-top: 3.25rem !important;
}
</style>
