import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/Site.scss'
require('./assets/css/Site.scss');

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faDiscord, faFacebook, faTwitch, faTwitter, faYoutube);

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(store).use(router).mount('#app');
